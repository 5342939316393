import Axios from "axios";

let http = "https://app.gobiernocontigo.com/api/v1/";

export default {
  index() {
    return Axios.get(`${http}usertype/`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },
};
