<template>
  <InputText
    type="text"
    v-model="localItem.name"
    placeholder="Nombre *"
    disabled
  />
  <Textarea
    v-model="localItem.description"
    :autoResize="true"
    rows="5"
    cols="30"
    placeholder="Descripción"
    disabled
  />
  <Toast />
</template>

<script>
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      localItem: {
        name: "",
        description: "",
        color: "",
        active: true,
      },
      isLoading: false,
    };
  },
  emits: ["onExit"],
  mounted() {
    this.localItem = this.item;
  },
};
</script>

<style></style>
